import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FirstRating from '../../HomePage/RatingReviews/FirstRating/FirstRating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReviewsSection from '../../Common_Component/ReviewsSection/ReviewsSection';
import './TopReview.css'


const TopReview = ({reviews,peoplesCount}) => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const ReviewsContent = [
        {
            username: "Rani das",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Saurav Khambayte",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
    ]

    return (
        <section className='top-review-main mt-5'>
            <Container>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='d-flex'>
                            <FontAwesomeIcon icon="fa-solid fa-arrow-up-wide-short" className='me-3' />
                            <div className='top-review'>
                                <select value={selectedOption} onChange={handleDropdownChange}>
                                    <option value="">Top Reviews</option>
                                    <option value="option1">Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                </select>
                            </div>
                        </div>
                        {reviews.map((item, index) => (
                            index <= 2 ? (
                            <Col xxl={12} key={index}>
                                <ReviewsSection
                                    username={item?.o_w_user?.first_name + ' ' + item?.o_w_user?.last_name}
                                    productname={item.name}
                                    location={item.location}
                                    date={item.createdAt}
                                    star={item?.rating}
                                />
                            </Col>
                            ):('')
                        ))}
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FirstRating peoplescount={peoplesCount}/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default TopReview