import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

// export const sendMobileOTP = async (contact_no) => {
//   try {
//     const res = await postData(`/seller/verify-otp/mobile-otp-send`, {
//       contact_no,
//     });

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const inquryformPost = async (data) => {
  try {
    return await postData(`/inquiry-website/querypost`, data);
  } catch (error) {
    console.log(error);
  }
};
export const aboutBanner = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/about-us/banner?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const allourstory = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/about-us/our-story?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getwhychooseus = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/about-us/why-choose?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getHappyCustomers = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/about-us/happy-customer?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const lookingFor = async (data) => {
  try {
    return await postData(`/inquiry-website/looking-for`, data);
  } catch (error) {
    console.log(error);
  }
};
export const contactUs = async (data) => {
  try {
    return await postData(`/inquiry-website/contact-us`, data);
  } catch (error) {
    console.log(error);
  }
};

export const homeReview = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/home/all-review?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};
export const products = async (seller_id, category_id) => {
  try {
    return await getData(
      `/inquiry-website/home/products?seller_id=${seller_id}&s_category_id=${category_id}&type=${"inquiry"}`
    );
  } catch (error) {
    console.log(error);
  }
};
export const categorys = async (seller_id, type) => {
  try {
    return await getData(
      `/inquiry-website/home/category?seller_id=${seller_id}&type=${type}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const PrivacyPolicyData = async (seller_id, type) => {
  try {
    return await getData(
      `/inquiry-website/policy/privacy-policy?seller_id=${seller_id}&type=${type}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const TermConditionData = async (seller_id, type) => {
  try {
    return await getData(
      `/inquiry-website/policy/term-condition?seller_id=${seller_id}&type=${type}`
    );
  } catch (error) {
    console.log(error);
  }
};
