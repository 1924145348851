import React, { useState } from "react";
import "./LookingFor.css";
import { Container } from "react-bootstrap";
import MyWebsite4_Inqury_form from "../../Common_Component/MyWebsite4_Inqury_form/MyWebsite4_Inqury_form";
function LookingFor() {

  const [showToast, setShowToast] = useState(false);

  // Define the function to handle hiding the modal or toast
  const handleClose = () => {
    setShowToast(false);
  };
  return (
    <>
      <section className="lookingfor_section">
        <Container>
          <h2 className="hometitle mb-4">
            Tell Us What Are You Looking For ?
          </h2>
          <div>
            <MyWebsite4_Inqury_form onHide={handleClose}/>
          </div>
        </Container>
      </section>
    </>
  );
}

export default LookingFor;
