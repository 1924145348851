import React, { useState, useEffect, useContext } from "react";
import TopReview from './TopReview/TopReview'
import ViewLessReview from './ViewLessReview/ViewLessReview'
import SendReview from './SendReview/SendReview';
import { Context } from '../../utils/context';

const ViewMoreCategory = () => {
    const { IMG_URL, userdata, getData, deleteData, sellerId } = useContext(Context);
    const [reviews, setReviews] = useState([]);
    const [peoplesCount, setPepoleCount] = useState([]);
    const getReviews = async () => {
        const res = await getData(`/inquiry-website/home/all-review`);
        console.log(res, "setReviews");
        if (res?.success) {
            setReviews(res?.data);
            setPepoleCount(res?.data?.length)
        }
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear(); // Gets the year (4 digits)
        const day = date.getDate(); // Gets the day of the month (1-31)
        const month = date.getMonth() + 1; // Gets the month (0-11, thus add 1)

        // Formatting to 'year-day-month' and ensuring two digits for day and month
        return `${year}-${String(day).padStart(2, '0')}-${String(month).padStart(2, '0')}`;
    }
    useEffect(() => {
        getReviews();
    }, []);
    return (
        <>
            <TopReview reviews={reviews} peoplesCount={peoplesCount}/>
            <ViewLessReview reviews={reviews}/>
            {/* <SendReview /> */}
        </>
    )
}

export default ViewMoreCategory