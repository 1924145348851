import React, { useState } from "react";
import "./Inquiry.css";
import { Container } from "react-bootstrap";
import InquiryForm from "../../Common_Component/MyWebsite4_Inqury_form/InquiryForm/InquiryForm";
function Inquiry() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <section className="inquiry_section">
        <Container>
          <div className="inquiry_main">
            <h5 className="inquiry_text">
              What specific topic or subject would <br />
              you like the inquiry to be about?
            </h5>
            <div className="inquriy_button_div">
              {/* <button className="inquiry_button" onClick={() => setModalShow(true)} >Inquiry</button> */}
            </div>
          </div>
        </Container>
        <InquiryForm show={modalShow} onHide={() => setModalShow(false)} />
      </section>
    </>
  );
}

export default Inquiry;
