import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getFaqs = async () => {
  try {
    return await getData(`/without-login/all-faq-categories`);
  } catch (error) {
    console.log(error);
  }
};
export const Banners = async (sellerId) => {
  try {
    return await getData(
      `/inquiry-website/home/all-home-banner?seller_id=${sellerId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const Brands = async (id) => {
  try {
    return await getData(`/without-login/masters/all-s-brand?seller_id=${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Country = async () => {
  try {
    return await getData(`/without-login/home/get-country`);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  try {
    return await getData(`/without-login/home/get-state/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/without-login/home/get-city/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (id) => {
  try {
    return await getData(`/without-login/home/get-pincode/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const SellerGet = async () => {
  try {
    return await getData(`/seller/show`);
  } catch (error) {
    console.log(error);
  }
};

export const GetAllWebsiteFaq = async (seller_id, type) => {
  try {
    return await getData(
      `/inquiry-website/faq/all-faq?seller_id=${seller_id}&type=${type}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllExclusiveCollection = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/home/all-exclusive-collection?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllCrazyDeals = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/home/all-crazy-deals?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllShopTrends = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/home/all-shop-trends?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllShopByCategory = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/home/all-shop-by-category?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllNewArrivals = async (s_supplyer_id) => {
  try {
    return await getData(
      `/inquiry-website/home/all-new-arrivals?s_supplyer_id=${s_supplyer_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAllHomeBanner = async () => {
  try {
    return await getData(`/inquiry-website/home/all-home-banner`);
  } catch (error) {
    console.log(error);
  }
};

export const GetAllWishList = async (id) => {
  try {
    return await postData(`/inquiry-website/wishlist/add`, { product_id: id });
  } catch (error) {
    console.log(error);
  }
};

export const GetWebHeader = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/home/web-header?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetAppSetup = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/home/app-setup?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetSocialMedia = async (seller_id) => {
  try {
    return await getData(
      `/inquiry-website/home/social-media?seller_id=${seller_id}`
    );
  } catch (error) {
    console.log(error);
  }
};
