import React, { useState } from "react";
import "./Product_Cart_Inner.css";
import { Breadcrumb, Button, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Product_Details_Div from "../Common_Component/Product_Details_Div/Product_Details_Div";
import Grand_Total_Table from "../Common_Component/Grand_Total_Table/Grand_Total_Table";
import Heart_Component from "../Common_Component/Heart_Component/Heart_Component";
import { faCartShopping, faTrash } from "@fortawesome/free-solid-svg-icons";
import Heading_Holder from "../Common_Component/Heading_Holder/Heading_Holder";
import Incre_Decre_btn from "../Common_Component/Incre_Decre_btn/Incre_Decre_btn";
import BreadcrumComponent from "../Common_Component/BreadcrumComponent/BreadcrumComponent"
function Product_Cart_Inner() {
  const [numberOne, setNumberOne] = useState(1);
  const [numberTwo, setNumberTwo] = useState(1);


  const breadcrumbItems = [
    { text: "Home", link: "/" },
    { text: "Back", link: "/productdescription" , className:"active"  },

  ];

  return (
    <>
      <div className="Product_Cart ">
        <div className="container">
          <BreadcrumComponent items={breadcrumbItems} />
          <div className="row">
            <div className="col-xxl-9 col-xl-9 col-lg-8">
              <div className="product_table">
                <div className="Heading_text_Holder">
                  <Heading_Holder
                    headingText={
                      <>
                        {" "}
                        <FontAwesomeIcon
                          className="Cart_icon"
                          icon={faCartShopping}
                        />
                        In My Cart
                      </>
                    }
                  />
                </div>
                <div className="row tabless mb-4">
                  <div className=" col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-5 col-12 ">
                    <div className="productimg">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png"
                        }
                        className="cartimg"
                      />
                    </div>
                  </div>
                  <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-9 col-sm-7 col-12 ">
                    <div className="firstRow">
                      <Product_Details_Div
                        subheading="Peter England -"
                        spanText=" Formal Shirt"
                        productDescription="Men Multi Slim Fit Full Sleeves Printed Shirts"
                        Price="₹ 1,234.00"
                        subPrice="₹ 1,234.00"
                      />

                      <div className="prices">
                        <p className="prce">
                          <Heart_Component />
                        </p>
                        <p className="prce">
                          <FontAwesomeIcon
                            className="trash_icon"
                            icon={faTrash}
                          />
                        </p>
                      </div>
                    </div>

                    <div className="B2b-Seller-Detail">
                      <div className="Background-image">
                        <div className="product-information">
                          <div className="">
                            <div className="subText_holder">
                              <p className="subText">SIZE</p>
                            </div>
                            <div className="sizeSection">
                              <div className="sizeNumber button_slide slide_down active">
                                38
                              </div>
                              <div className="sizeNumber button_slide slide_down">
                                39
                              </div>
                              <div className="sizeNumber button_slide slide_down">
                                40
                              </div>
                              <div className="sizeNumber button_slide slide_down">
                                41
                              </div>
                              <div className="sizeNumber button_slide slide_down">
                                42
                              </div>
                              <div className="sizeNumber button_slide slide_down">
                                43
                              </div>
                              <div className="sizeNumber button_slide slide_down">
                                44
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <div className="subText_holder">
                              <p className="subText">QUANTITY</p>
                            </div>

                            <Incre_Decre_btn />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to={"/"}>
                <div className="Continue_Shopping_text">
                  <p>Continue Shopping</p>
                </div>
              </Link>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-4">
              <Grand_Total_Table />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product_Cart_Inner;
