import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReviewsSection from '../../Common_Component/ReviewsSection/ReviewsSection'
import './ViewLessReview.css'

const ViewLessReview = ({ reviews }) => {
    const ReviewsContent = [
        {
            username: "Rani das",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Saurav Khambayte",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Rani das",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Saurav Khambayte",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Rani das",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Saurav Khambayte",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Rani das",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Saurav Khambayte",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Rani das",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },
        {
            username: "Saurav Khambayte",
            productname: "Product Name: Makeup Products",
            location: "New Delhi",
            date: "5 Nov 24",
        },

    ]

    return (
        <section className='view-less-review'>
            <Container>
                <Row>
                    {reviews.map((item, index) => (
                        index >= 3 ? (
                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}  className='mt-4'>
                                <ReviewsSection
                                    username={item?.o_w_user?.first_name + ' ' + item?.o_w_user?.last_name}
                                    productname={item.name}
                                    location={item.location}
                                    date={item.createdAt}
                                    star={item?.rating}
                                />
                            </Col>
                        ) : ('')
                    ))}
                    <div className='text-center mt-5'>
                        <button className='view-less-review-btn'>View Less Reviews</button>
                    </div>
                </Row>
            </Container>
        </section>
    )
}

export default ViewLessReview