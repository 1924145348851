import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ExploreTrending.css";
import ProductCard from "../../Common_Component/Product_Card/ProductCard";
import { Context } from "../../../utils/context";
import { PopularProductdetails } from "../../../utils/apis/Product/Product";

const ExploreTrending = () => {
  const { IMG_URL, userdata, getData, deleteData, sellerId } =
    useContext(Context);

  const [product, setproduct] = useState([]);
  const getTreandingProduct = async () => {
    const res = await PopularProductdetails(sellerId);
    if (res?.success) {
      console.log(res?.data);
      setproduct(res?.data);
    }
  };

  useEffect(() => {
    getTreandingProduct();
  }, [sellerId]);

  return (
    <section className="explore-trendong">
      <Container>
        <div className="title-common">
          <h3 className="hometitle mb-4">Explore Our Trending Products</h3>
        </div>
        <Row>
          {product.map((item, index) => (
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={6}
              sm={6}
              xs={12}
              key={index}
              className="mb-3"
            >
              <ProductCard
                images={item.thumbnail}
                cardtitle={item.product_generic_name}
                cardtext={item.cardtext}
                productId={item.id}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ExploreTrending;
