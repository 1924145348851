import React, { useState, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../utils/context";
import { lookingFor } from "../../../utils/apis/common/Common";
import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from "../Buttons/Buttons";
import "./MyWebsite4_Inqury_form.css";
import { ToastContainer, toast } from "react-toastify";
import { RegxExpression } from "../../../utils/common";

function MyWebsite4_Inqury_form(props) {
  const { getData, IMG_URL, Select2Data, sellerId } = useContext(Context);
  const [showToast, setShowToast] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();

  const notify = () => {
    console.log("Cal");
    toast("Review Submitted Successfully!", { autoClose: 3000 });
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
      props.onHide();
    }, 3000);
  };

  const onSubmit = async (data) => {
    data.seller_id = sellerId;
    const res = await lookingFor(data);
    if (res?.success) {
      console.log("adasd");
      notify(true);
      reset();
    } else {
      console.error("Error fetching data:", res?.error);
      toast("Error submitting review. Please try again.", { autoClose: 3000 });
    }
  };

  return (
    <section className="inqury_form">
      <div className="inqury_form_div">
        <Form>
          <Col lg={8} className="mx-auto">
            <div className="inquiryformbackground">
              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      name="message"
                      rows={4}
                      className={classNames("", {
                        "is-invalid": errors?.message,
                      })}
                      {...register("message", {
                        required: "Message is required",
                      })}
                      type="text"
                      placeholder="Describe your requirement in detail"
                    />
                  </Form.Group>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                      })}
                      {...register("contact_no", {
                        required: "contact no is required",
                        pattern: {
                          value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                          message: "Contact number Invalid",
                        },
                      })}
                      type="text"
                      placeholder="Contact Number"
                      // onKeyDown={(event) => {
                      //   if (!RegxExpression.contact_no?.test(event.key)) {
                      //     if (event.key !== "Backspace") {
                      //       event.preventDefault();
                      //     }
                      //   }
                      // }}
                      maxLength={10}
                    />
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      {...register("name", {
                        required: "Name is required",
                      })}
                      type="text"
                      placeholder="Enter Your Name"
                    />
                  </Form.Group>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                  <div className="inqu_button_div">
                    <Buttons
                      text="Inquiry"
                      className="form_inqury_button"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Form>
        <div className="tonstify_section">
          {showToast && <ToastContainer autoClose={3000} />}
        </div>
      </div>
    </section>
  );
}

export default MyWebsite4_Inqury_form;
