import React from "react";
import "./FirstRating.css";
import ReactStars from "react-stars";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const FirstRating = ({ peoplescount }) => {
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };
  const percentage = (peoplescount * 100) / 100;
  return (
    <section className="first-rating">
      <div className="main-below-txtt">
        <img
          src={process.env.PUBLIC_URL + "/assets/Home/Explore_ Brands/girl.png"}
          className="girl-img"
          alt="..."
        />
        <div className="below-txtt">
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            strokeWidth={12}
            className="circulkarProgress"
            styles={buildStyles({
              textColor: "#fff",
              pathColor: "#FF7C00",
            })}
          />
          <p className="reviews-txtttt mt-3">
            Reviews By {peoplescount} Peoples
          </p>
          <ReactStars
            count={5}
            value={percentage}
            onChange={ratingChanged}
            size={24}
            color2={"#ffd700"}
            className="star-img"
          />
          {/* <Link to='/view-more-category'>
                        <button className='inquiry-btn'>Write a Review</button>
                    </Link> */}
        </div>
      </div>
    </section>
  );
};

export default FirstRating;
