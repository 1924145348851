import React from 'react'
import { Rating } from "react-simple-star-rating";
import "./Reviews.css"
import Buttons from '../../Common_Component/Buttons/Buttons';
const Reviews = () => {
    return (
        <>
            <section className='Reviews'>
                <div className='container'>
                    <div className='row RatingmainRow'>
                        <div className='col-lg-5'>
                            <div className='ratingStarsec'>
                                <Rating initialValue={0} SVGclassName="star-svg" fillColor="#1D44AD" size={"25px"} />
                                <p>Rating (4/5)</p>

                            </div>

                            <div className='whatYoulikesec'>
                                <h3>What did you like?</h3>
                                <div>
                                    <textarea rows={5} className='textareaRating' placeholder='Tell us more (not more than 700 characters)' />
                                </div>
                            </div>

                            <div className='ratingbtnsec'>
                                <Buttons className="cancelbtn" text={"Cancel"} />
                                <Buttons className="postbtn" text={"Post"} />

                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='ReviewsContentsec'>
                                <h3>Top 3 Reviews</h3>

                                <div className='specificReviews'>
                                    <h3>John Doe</h3>
                                    <p>The Slim Fit Printed Shirt offers a perfect blend of style and comfort, with its sleek design and premium-quality fabric ensuring a flattering fit for any occasion. Elevate your wardrobe effortlessly with this versatile essential.</p>
                                    <div className='ratingDatesec'>
                                        <Rating initialValue={0} SVGclassName="star-svg" fillColor="#1D44AD" size={"15px"} />
                                        <p>05/3/2024</p>
                                    </div>

                                </div>

                                <div className='specificReviews'>
                                    <h3>John Doe</h3>
                                    <p>The Slim Fit Printed Shirt offers a perfect blend of style and comfort, with its sleek design and premium-quality fabric ensuring a flattering fit for any occasion. Elevate your wardrobe effortlessly with this versatile essential.</p>
                                    <div className='ratingDatesec'>
                                        <Rating initialValue={0} SVGclassName="star-svg" fillColor="#1D44AD" size={"15px"} />
                                        <p>05/3/2024</p>
                                    </div>

                                </div>

                                <div className='specificReviews'>
                                    <h3>John Doe</h3>
                                    <p>The Slim Fit Printed Shirt offers a perfect blend of style and comfort, with its sleek design and premium-quality fabric ensuring a flattering fit for any occasion. Elevate your wardrobe effortlessly with this versatile essential.</p>
                                    <div className='ratingDatesec'>
                                        <Rating initialValue={0} SVGclassName="star-svg" fillColor="#1D44AD" size={"15px"} />
                                        <p>05/3/2024</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Reviews