import React from "react";
import "./Banner_Card.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCircle } from "@fortawesome/free-solid-svg-icons";
import Product_Details_Div from "../Product_Details_Div/Product_Details_Div";
import { Link } from "react-router-dom";

function Banner_Card({
  className,
  detailsLink,
  overlayClassName,
  imgSource,
  imgClassName,
  overlayText,
  background,
  textColor,
  padding,
  overlaySubText,
  heartClassName,
  HeartLikeComponent,
  ProductDetailsSection,
  ProductDetailsClassName,
}) {
  const overlayStyle = {
    background,
    textColor,
    padding,
  };



  return (
    <section className="Banner_Card">
      <div className={className}>
        <div className="img-holder">
        <Link to={detailsLink}>
          <img className={imgClassName} src={imgSource}  />
        </Link>
        </div>

        <div className={heartClassName}>{HeartLikeComponent}</div>

        <div className={overlayClassName} style={overlayStyle}>
          <p className="overlayText">{overlayText}</p>
          <p className="overlaySubText">{overlaySubText}</p>
        </div>
      </div>

      <div className={ProductDetailsClassName}>{ProductDetailsSection}</div>
    </section>
  );
}

export default Banner_Card;
