import React, { useEffect, useState, useContext } from "react";
import "./Footer.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Nav } from "react-bootstrap";

import {
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../utils/context";
import { GetAppSetup, GetWebHeader, GetSocialMedia } from "../../utils/apis/master/Master";

function Footer() {
  const { signin, setSignin, sellerId, IMG_URL } = useContext(Context);

  const navigate = useNavigate();
  const [headers, setHeaders] = useState([]);
  const getHeader = async () => {
    const res = await GetWebHeader(sellerId);
    if (res?.success) {
      setHeaders(res?.data);
    }
  };

  const [appSetup, setAppSetup] = useState();
  const getAppSetup = async () => {
    const res = await GetAppSetup(sellerId);
    if (res?.success) {
      setAppSetup(res?.data);
    }
  };
  const [socialMedia, setSocialMedia] = useState();
  const getSocialLink = async () => {
    const res = await GetSocialMedia(sellerId);
    if (res?.success) {
      setSocialMedia(res?.data);
    }
  };
  console.log(socialMedia, "socialMedia");
  useEffect(() => {
    getHeader();
    getAppSetup();
    getSocialLink();
  }, [sellerId]);
  return (
    <>
      {/* <section
        className="footer-section"
        style={{
          background: appSetup?.footer_color,
        }}
      >
        <Container fluid>
          <Container>
            <div
              className=" footermains pt-4 pb-3"
              style={{
                borderBottom: `1px solid`+ appSetup?.footer_font_color,
              }}
            >
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <div className="footer-col">
                    <div className="toptext  mb-lg-5">
                      <Link to={"/"}>
                        <img
                          src={IMG_URL + appSetup?.image}
                          className="footer-logos"
                          alt="..."
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Link to={"/faq"}>
                    <p
                      className="linktxt"
                      style={{
                        color: appSetup?.footer_font_color,
                      }}
                    >
                      FAQs
                    </p>
                  </Link>
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Link to={"/contact-us"}>
                    <p
                      className="linktxt"
                      style={{
                        color: appSetup?.footer_font_color,
                      }}
                    >
                      CONTACT US
                    </p>
                  </Link>
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Link to={""}>
                    <p
                      className="linktxt"
                      style={{
                        color: appSetup?.footer_font_color,
                      }}
                    >
                      PRIVACY POLICY
                    </p>
                  </Link>
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Link to={""}>
                    <p
                      className="linktxt"
                      style={{
                        color: appSetup?.footer_font_color,
                      }}
                    >
                      TERMS OF USE
                    </p>
                  </Link>
                </Col>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="mt-md-3"
                >
                  <Link to={""}>
                    <p
                      className="linktxt"
                      style={{
                        color: appSetup?.footer_font_color,
                      }}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-phone"
                        className="phoneicn"
                      />{" "}
                      {appSetup?.contact_no}
                    </p>
                  </Link>
                </Col>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="mt-md-3"
                >
                  <Link to={""}>
                    <p
                      className="linktxt"
                      style={{
                        color: appSetup?.footer_font_color,
                      }}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-envelope"
                        className="phoneicn"
                      />{" "}
                      {appSetup?.email}
                    </p>
                  </Link>
                </Col>
              </Row>

              <div className="text-center mt-3">
                <p
                  className="linktxt"
                  style={{
                    color: appSetup?.footer_font_color,
                  }}
                >
                  {appSetup?.address}
                </p>
              </div>
            </div>
            <div className="bottomtext">
              <div className="botright d-flex align-items-center">
                <p
                  className="copyright"
                  style={{
                    color: appSetup?.footer_font_color,
                  }}
                >
                  ©NETPURTI. All Rights Reserved (Terms of Use) <br /> Developed
                  and Managed by Profcyma.
                </p>
              </div>
              <p className="copyright">
                <div className="bottomimag">
                  <Link to={"/"}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Websitelogo/netpurti_logo.png"
                      }
                      className="footer_bottom_logos"
                      alt="..."
                    />
                  </Link>
                </div>
              </p>
            </div>
          </Container>
        </Container>
      </section> */}
      <section className="footer" style={{
        background: appSetup?.footer_color,
      }}>
        <Container>
          <div className='row'>
            <div className='col-lg-3 col-md-4  '>
              <Link as={Link} to="/"><img className='headlogo' src={IMG_URL + appSetup?.image} alt="Logo" /></Link>
              <div className='text-area-loaction'>
                <p style={{
                  color: appSetup?.footer_font_color,
                }}>{appSetup?.address}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-8  ">
              <Nav
                defaultActiveKey="/"
                className="justify-content-lg-center justify-content-md-center justify-content-start"
              >
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/" style={{
                    color: appSetup?.footer_font_color,
                  }}>
                    HOME
                  </NavLink>
                </Nav.Item>

                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/about-us" style={{
                    color: appSetup?.footer_font_color,
                  }}>
                    ABOUT
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/contact-us" style={{
                    color: appSetup?.footer_font_color,
                  }}>
                    CONTACT
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/Privacy-policy" style={{
                    color: appSetup?.footer_font_color,
                  }}>
                    PRIVACY POLICY
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/terms-condition" style={{
                    color: appSetup?.footer_font_color,
                  }}>
                    TERMS OF USE
                  </NavLink>
                </Nav.Item>
              </Nav>
              <div className="location-holder">
                <div className="call-holder me-4">
                  <FontAwesomeIcon
                    icon="fa-solid fa-phone"
                    className="call-icon me-2"
                  />{" "}
                  <span className="me-2" style={{
                    color: appSetup?.footer_font_color,
                  }}> {appSetup?.contact_no}</span>
                </div>
                <div className="call-holder ">
                  <FontAwesomeIcon
                    icon="fa-solid fa-envelope"
                    className="call-icon me-2"
                  />
                  <span style={{
                    color: appSetup?.footer_font_color,
                  }}> {appSetup?.email}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-5">
              <div className="address-holder">
                <div className="text-holder">
                  <p style={{
                    color: appSetup?.footer_font_color,
                  }}>Follow Us On:</p>
                </div>
                <div className="social-linksss d-flex">
                  {socialMedia?.map((val) => (

                    <a href={`${val?.link ? val.link : '#'}`}> {/* If val.link is not available, fallback to '#' */}
                     <div className="footer-soc-logo-holder">

                      <img
                        className="sociallogo"
                        src={IMG_URL + val?.image}
                        alt="sociallogo"
                      />
                     </div>
                    </a>

                  ))}
                  {/* <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faFacebookF} className="fa-logo" />
                  </div>
                  <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faLinkedin} className="fa-logo" />
                  </div>
                  <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faTwitter} className="fa-logo" />
                  </div>
                  <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faYoutube} className="fa-logo" />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="copyright-holder  text-center">
                <p style={{
                  color: appSetup?.footer_font_color,
                }}>
                  ©NETPURTI. All Rights Reserved (Terms of Use)  Developed
                  <Link to="https://profcymaglobal.com/" target="___blank">
                    and Managed by Profcyma.
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Footer;
