import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";
  
  // export const orderGet = async (sellerId) => {
  //   try {
  //     const res = await getData(`/outer-website/order-process/orderget`);
  
  //     return res;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  
  export const getDomainDataPost = async (data) => {
    try {
      const res = await postData(`/seller/seller-create/s-over-view/domainData`,data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  
  
  
  