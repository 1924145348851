import React from "react";
import { Col, Row } from "react-bootstrap";
import ReactStars from "react-stars";
import "./ReviewsSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReviewsSection = ({ username, productname, location, date, star }) => {
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };
  return (
    <section className="main-reviews-sec">
      {/* <Row>
                <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={4}>
                    <div className='main-user-img'>
                        <img src={process.env.PUBLIC_URL + "/assets/Home/Reviews/user.png"} className="user-img" alt="..." />
                    </div>
                </Col>
                <Col xxl={10} xl={10} lg={9} md={9} sm={8} xs={8}>
                    <div className='d-flex justify-content-between'>
                        <p className='user-name'>{username}</p>
                        <div>
                            <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                color2={'#ffd700'}
                                className="reviewclass"
                            />

                        </div>
                    </div>
                    <p className='reviews-txtt'>{productname}</p>
                    <Row className='mt-3'>
                        <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={12}>
                            <p className='reviews-txtt'><FontAwesomeIcon icon="fa-solid fa-location-dot" className='me-2' />{location}</p>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={12}>
                            <p className='reviews-txtt'><FontAwesomeIcon icon="fa-solid fa-calendar-days" className='me-2' />{date}</p>
                        </Col>
                    </Row>
                </Col>
            </Row> */}

      <div className="review_card_main">
        <div className="main-user-img">
          <img
            src={process.env.PUBLIC_URL + "/assets/Home/Reviews/user.png"}
            className="user-img"
            alt="..."
          />
        </div>
        <div className="userdetaless">
          <div className="d-flex justify-content-between">
            <p className="user-name">{username}</p>
            <div>
              <ReactStars
                count={5}
                value={star}
                onChange={ratingChanged}
                color2={"#ffd700"}
                className="reviewclass"
              />
            </div>
          </div>
          <p className="reviews-txtt">{productname}</p>
          <div className="mt-3 d-flex flex-wrap">
            {/* <div className="me-5">
              <p className="reviews-txtt">
                <FontAwesomeIcon
                  icon="fa-solid fa-location-dot"
                  className="me-2"
                />
                {location}
              </p>
            </div> */}
            <div>
              <p className="reviews-txtt">
                <FontAwesomeIcon
                  icon="fa-solid fa-calendar-days"
                  className="me-2"
                />
                {date}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='demo'></div> */}
    </section>
  );
};

export default ReviewsSection;
