import React, { useState, useEffect, useContext } from "react";
import ReviewsSection from "../../../Common_Component/ReviewsSection/ReviewsSection";
import { Col } from "react-bootstrap";
import "./Review.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { homeReview } from "../../../../utils/apis/common/Common";
const Reviews = () => {
  const { IMG_URL, userdata, getData, deleteData, sellerId } =
    useContext(Context);
  console.log(sellerId, "sellerId");
  const [reviews, setReviews] = useState([]);

  const getReviews = async () => {
    const res = await homeReview(sellerId);
    console.log(res, "setReviews");
    if (res?.success) {
      setReviews(res?.data);
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear(); // Gets the year (4 digits)
    const day = date.getDate(); // Gets the day of the month (1-31)
    const month = date.getMonth() + 1; // Gets the month (0-11, thus add 1)

    // Formatting to 'year-day-month' and ensuring two digits for day and month
    return `${year}-${String(day).padStart(2, "0")}-${String(month).padStart(
      2,
      "0"
    )}`;
  }
  useEffect(() => {
    getReviews();
  }, []);
  return (
    <section className="review">
      {reviews.map((item, index) =>
        index <= 3 ? (
          <Col xxl={12} key={index}>
            <ReviewsSection
              username={item?.name}
              productname={item?.s_product?.name}
              location={item.location}
              date={formatDate(item.createdAt)}
              star={item?.rating}
            />
          </Col>
        ) : (
          ""
        )
      )}
      {reviews.length > 3 ? (
        <div className="text-center mt-4">
          <Link to="/view-more-category">
            <button className="view-more-button">View More Reviews</button>
          </Link>
        </div>
      ) : (
        ""
      )}
    </section>
  );
};

export default Reviews;
