import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import "./Heart_Component.css";

function Heart_Component() {
  const [heartactive, setheartActive] = useState(false);
  const handleClickes = () => {
    setheartActive(!heartactive);
  };
  return (
    <>
      <div className="hearticn">
        <FontAwesomeIcon
          icon={faHeart}
          onClick={handleClickes}
          className={heartactive ? "gobiheartss" : "gobiheart"}
        />
      </div>
    </>
  );
}

export default Heart_Component;
