import React from "react";
import Lottie from "react-lottie";
// import * as animationData from './empty- 1713790286270.json';
import * as animationData from "./empty-box.json";

const EmptyCategory = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="main">
      <div className="Successfull_Lottie_icon">
        <Lottie
          className="me-auto"
          options={defaultOptions}
          // height={60}
          // width={60}
        />
      </div>
    </section>
  );
};

export default EmptyCategory;
