import React from 'react'
import ScrollToTop from './Component/ScrollTotop/ScrollTotop'
import Header from './Component/Navbar/Header/Header';
import { Route, Routes, useLocation } from "react-router-dom";
import "./Component/Common_Css/Text_Css/Text_Css.css"
import "./Component/Common_Css/Swiper_Slider_Css/Swiper_Slider_Css.css"
import Footer from './Component/Footer/Footer';
import "./App.css"
import AllRoutes from './Component/AllRoutes/AllRoutes';
import AppContext from  "./utils/context";

function App() {
  return (
    <>
      <AppContext>
        <ScrollToTop />
        <Header />
        <AllRoutes />
        <Footer />
      </AppContext>
    </>
  )
}

export default App