import React, { useState, useEffect, useContext } from 'react'
import { Container, Row } from 'react-bootstrap'
import './PrivacyPolicy.css'
import { TermConditionData } from "../../utils/apis/common/Common";
import { Context } from "../../utils/context";
const TermsAndCondition = () => {
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();
      const { IMG_URL, userdata, getData, deleteData, sellerId, type } = useContext(Context);
      console.log(sellerId);
      const [banner, setBanner] = useState([]);
      // Define an array of slide data
      const getBanners = async () => {
          const res = await TermConditionData(sellerId,type);
          if (res?.success) {
              setBanner(res?.data);
          }
      };
  
      useEffect(() => {
          getBanners();
      }, [sellerId]);
    return (
        <>
            <section className='privacy-policy'>
                <Container>
                    <Row>
                        <div className='heading-holder mb-4'>
                            <h1> TERMS OF USE</h1>
                        </div>
                        <div className='text-holder'>
                        <p>{htmlToReactParser.parse(banner?.name)}</p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default TermsAndCondition
