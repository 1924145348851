import React from "react";
import Home_Banner from "./Home_Banner/Home_Banner";
import BrowseCatogary from "./BrowseCatogary/BrowseCatogary";
import Inquiry from "./Inquiry/Inquiry";
import CustomerStories from "./CustomerStories/CustomerStories";
import LookingFor from "./LookingFor/LookingFor";
import ExploreTrending from "./ExploreTrending/ExploreTrending";
import RatingReviews from "./RatingReviews/RatingReviews";


function HomePage() {
  return (
    <>
      <Home_Banner />
      <BrowseCatogary/>
      <Inquiry/>
      <ExploreTrending />
      {/* <CustomerStories/> */}
      <LookingFor/>
      
      <RatingReviews />
    </>
  );
}

export default HomePage;
