import React, { useState, useEffect, useContext } from "react";
import "./ProductCard.css";
import { Button, Card } from "react-bootstrap";
import InquiryForm from "../MyWebsite4_Inqury_form/InquiryForm/InquiryForm";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ images, cardtitle, cardtext, productId }) => {
  const navigate = useNavigate();
  const [product, setProdcut] = useState({});
  const { IMG_URL, userdata, getData, deleteData, sellerId } =
    useContext(Context);
  const [modalShow, setModalShow] = React.useState(false);
  const handelClick = async (proId) => {
    console.log(proId, "ss");
    navigate(`/productdescription/${proId}`);
  };
  return (
    <div className="pro-card">
      <Card className="main-pro-card">
        <div className="sub-main-img">
          <Card.Img
            variant="top"
            className="brand-img"
            src={IMG_URL + images}
          />
        </div>
        <Card.Body>
          <Card.Title>{cardtitle}</Card.Title>
          <Card.Text>
            <p className="card-txttt">{cardtext}</p>
          </Card.Text>
          <div className="text-center">
            <div onClick={() => handelClick(productId)}>
              <button
                className="inquiry-btn"
                onClick={() => setModalShow(true)}
              >
                Product Detail
              </button>
            </div>
          </div>

          <InquiryForm show={modalShow} onHide={() => setModalShow(false)} />
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductCard;
