import React from 'react'
import './Buttons.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Buttons({
  onClick,
  className,
  margin,
  text,
  border,
  background,
  borderRadius,
  fontSize,
  color,
  fontWeight,
  padding,
  icon,
  showHeart,
}) {

  const buttonStyle = {
    background,
    borderRadius,
    border,
    fontSize,
    fontWeight,
    color,
    padding,
    margin,
    className,
  };
  return (
    <>
      <button onClick={onClick} type="button" className={className} style={buttonStyle}>
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className={`heart-icon me-2 ${showHeart ? "heart-icon  active" : ""}`}
          />
        )}
        {text}
      </button>
    </>
  )
}

export default Buttons