import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
library.add(fas);



const Shipping_information = ({ handleNext, handlePrevious }) => {
    const [isSaved, setIsSaved] = useState(false);


    const handlebackPrevious = () => {
        if (showInnerPage) {
            setIsSaved(true);
            setShowInnerPage(false);
        } else {

            handlePrevious();
        }
    }

    const handleNextStep = () => {
        if (showInnerPage) {
            handleNext();
        } else {

            setIsSaved(true);
            setShowInnerPage(true);
        }
    };
    const [showInnerPage, setShowInnerPage] = useState(false)
    return (
        <>
            <section className='stepp-inner-main'>

                {
                    showInnerPage ? (

                        <div className='formSection'>
                            <Form>
                                <Col xl={7} className="mx-auto mb-3">
                                    <Form.Group controlId="formGridEmail">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter First Name" />
                                        <Form.Control type="text" placeholder="Enter Last Name" />
                                    </Form.Group>

                                    <Form.Group controlId="formGridPassword">
                                        <Form.Label >Contact</Form.Label>
                                        <Form.Control type="password" placeholder="Password" />
                                    </Form.Group>

                                    <Form.Group controlId="formGridAddress1">
                                        <Form.Label>Address</Form.Label>
                                        <div>
                                            <textarea rows={5} className='textAreacontent' />

                                        </div>
                                    </Form.Group>

                                    <Row className="mb-3">
                                        <div className='col-lg-6'>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control />
                                            </Form.Group>

                                        </div>
                                        <div className='col-lg-6'>
                                            <Form.Group as={Col} className='mb-3' controlId="formGridCity">
                                                <Form.Label>PINCODE</Form.Label>
                                                <Form.Control />
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-6'>
                                            <Form.Group as={Col} className='mb-3' controlId="formGridCity">
                                                <Form.Label>DISCTRICT</Form.Label>
                                                <Form.Control />
                                            </Form.Group>

                                        </div>
                                        <div className='col-lg-6'>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label>STATE</Form.Label>
                                                <Form.Control />
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-12'>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label>LANDMARK</Form.Label>
                                                <Form.Control />
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-12'>
                                            <div className='addressHead'>
                                                <h3>TYPE OF ADDRESS</h3>
                                            </div>
                                            <div className='checkboxfieldd'>
                                                <Form.Group className="addresscheck " id="formGridCheckbox">
                                                    <Form.Check type="radio" name='name' label="Residential" />
                                                </Form.Group>
                                                <Form.Group className="addresscheck" id="formGridCheckbox">
                                                    <Form.Check type="radio" name='name' label="Office" />
                                                </Form.Group>
                                                <Form.Group className="addresscheck" id="formGridCheckbox">
                                                    <Form.Check type="radio" name='name' label="Other" />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='text-center'>
                                            <Button type='button' className="back-btn" onClick={handlebackPrevious}>Back</Button>
                                            <Button className="continue-btn" onClick={handleNextStep}>
                                                {isSaved ? 'SAVE & NEXT' : 'SAVE'}
                                            </Button>

                                        </div>

                                    </Row>
                                </Col>






                            </Form>
                        </div>

                    ) :

                        (



                            <div className='address-div'>
                                <div className='title-div'>
                                    <h6 className='choss-add'>Choose Address</h6>
                                    <div className='dlttt-div'>
                                        <FontAwesomeIcon icon="fa-solid fa-trash-can" />
                                        <span>DELETE ADDRESS</span>
                                    </div>
                                </div>

                                <div className='row mt-5'>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='addd-card'>
                                            <div className='row'>
                                                <div className='col-10'>
                                                    <div className='card-flex'>
                                                        <div className='loc-img-div'>
                                                            <img className='map-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/map.png'} />
                                                            <img className='loc-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/location.png'} />
                                                        </div>
                                                        <div className='cont-div'>
                                                            <h6 className='hom'>Home</h6>
                                                            <p className='name'>John Doe</p>
                                                            <p className='adddd'>E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-2 text-end'>
                                                    <Form.Check type="radio" label="" />
                                                    <Link to="" className='edit'>Edit</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='addd-card'>
                                            <div className='row'>
                                                <div className='col-10'>
                                                    <div className='card-flex'>
                                                        <div className='loc-img-div'>
                                                            <img className='map-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/map.png'} />
                                                            <img className='loc-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/location.png'} />
                                                        </div>
                                                        <div className='cont-div'>
                                                            <h6 className='hom'>Work</h6>
                                                            <p className='name'>John Doe</p>
                                                            <p className='adddd'>E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-2 text-end'>
                                                    <Form.Check type="radio" label="" />
                                                    <Link to="" className='edit'>Edit</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-4 mb-3'>
                                        <div className='addd-card-new-add'>
                                            <button type='button' className='addnewaddbtn' onClick={handleNextStep}>Add New Address</button>
                                        </div>

                                    </div>

                                </div>


                            </div>

                        )
                }


                <div className="save-btn-div mt-5">
                    {/* <button type='button' className="back-btn" onClick={handlebackPrevious}>Back</button> */}
                    {/* <button type='button' className="continue-btn" onClick={handleNextStep}>
                        {isSaved ? 'SAVE & NEXT' : 'SAVE'}
                    </button> */}
                </div>
            </section>



        </>
    )
}

export default Shipping_information