import React, { useState, useEffect, useContext } from "react";
import ProductCart from "./ProductCart/ProductCart";
import SimilarPatterns from "./SimilarPatterns/SimilarPatterns"
import Reviews from "./Reviews/Reviews";
import Morebrands from "./Morebrands/Morebrands";
import { useParams } from "react-router-dom";
import { Context } from "../../utils/context";
const ProductDescription = () => {
    const { IMG_URL, userdata, getData, deleteData, sellerId } = useContext(Context);
    const [product, setproduct] = useState([]);

    const [category, setCategory] = useState("");
    const { id } = useParams();
    console.log(id, "proid");
    const getProductDetails = async () => {
        const res = await getData(`/inquiry-website/home/all-product-details?seller_id=${sellerId}&id=${id}`);
        if (res?.success) {
            setproduct(res?.data)
        }
    }
    useEffect(() => {
        getProductDetails();
    }, []);
    return (
        <>
            <ProductCart
                id={id}
                sellerId={sellerId}
                IMG_URL={IMG_URL}
                product={product}
                setCategory={setCategory}
            />
            {/* <Reviews />
            <Morebrands />
            <SimilarPatterns heading={"Similar Patterns for you"} />
            <SimilarPatterns heading={"Crafted for you"} /> */}

        </>
    );
};

export default ProductDescription;
